$gray: #757575;
$secondary: #F7941D;

.switch_form_wrapper {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    .switch_form_item {
        background-color: $gray;

        &.active {
            background-color: $secondary;
        }
    }

    & > div {
        display: flex;
    }

    &.switch_form_vertical > div {
        flex-direction: column !important;
    }

    &:not(.switch_form_vertical) > div {
        flex-direction: row !important;
        align-items: center;

        & > div {
            padding: 0 !important;
            width: auto !important;
        }
    }
}
