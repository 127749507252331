@import '../../themes/colors/colors.scss';

.table_wrapper {
  text-transform: capitalize;
  cursor: pointer;

  .text_primary {
    color: $secondary;
  }

  .text_secondary {
    color: $gray;
  }

  .flex_align_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg_success {
    background-color: $Green;
  }

  .bg_pending {
    background-color: $yellowDark;
  }

  .bg_warn {
    background-color: $Red;
  }

  .bg_secondary {
    background-color: $LightGray;
  }

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .big_dot {
    width: 0.70rem;
    height: 0.70rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-top: 0.1rem;

  }

  .cursor_pointer {
    cursor: pointer;
  }

  .editing {
    background-color: $editing !important;
  }

  .selected {
    background-color: #fff9eb;
  }

  .unselected {
    background-color: $white;
  }
}
